import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';


interface CharacterState {
  character: Character
}

const initialState: CharacterState = {
  character: {
    id: 0,
    userId: '',
    name: '',
    exhaustion: 0,
    speed: 30,
    age: 0,
    faithPoints: 0,
    restDice: { amount: 0, type: 0, display: '' },
    gender: '',
    breathersTaken: 0,
    weaponExhaustionMod: 0,
    characterClass: '',
    attackDice: null,
    defenseDice: null,
    level: 0,
    culture: '',
    dailySlots: 1,
    physical: { name: '', value: 0, prof: false },
    education: { name: '', value: 0, prof: false },
    technical: { name: '', value: 0, prof: false },
    social: { name: '', value: 0, prof: false },
    awareness: { name: '', value: 0, prof: false },
    skills: [],
    features: [],
    gp: 0,
    sp: 0,
    cp: 0,
    treasures: '',
    perks: [],
    animalsOwned: [],
    gear: [],
    specializedAmmo: [],
    blessings: [],
    divineRelics: [],
    weapons: [],
    skin: '',
    height: '',
    weight: '',
    eyes: '',
    hair: '',
    traits: [],
    alliesAndContacts: '',
    backstory: '',
    languages: '',
    companion: {
      name: '',
      creatureType: '',
      size: '',
      exhaustion: 0,
      attackDice: { amount: 0, type: 0, display: '' },
      defenseBonus: 0,
      resistances: '',
      vulnerabilities: '',
      speed: 0,
      attacks: '',
      specialAbilities: '',
      physical: { name: '', value: 0, prof: false },
      education: { name: '', value: 0, prof: false },
      technical: { name: '', value: 0, prof: false },
      social: { name: '', value: 0, prof: false },
      awareness: { name: '', value: 0, prof: false }
    },
  }
};

export const characterSlice = createSlice({
  name: 'character',
  initialState,
  reducers: {
    setCharacterData: (state, action) => {
      if (!action.payload) {
        state.character = initialState as any;
      } else {
        state.character = action.payload;
      }
    },
    setCharacterName: (state, action) => { state.character.name = action.payload; },
    setCharacterExhaustion: (state, action) => { state.character.exhaustion = action.payload; },
    setCharacterAge: (state, action) => { state.character.age = action.payload; },
    setCharacterRestDice: (state, action) => { state.character.restDice = action.payload; },
    setCharacterGender: (state, action) => { state.character.gender = action.payload; },
    setCharacterWeaponMod: (state, action) => { state.character.weaponExhaustionMod = action.payload; },
    setCharacterClass: (state, action) => { state.character.characterClass = action.payload; },
    setCharacterLevel: (state, action) => { state.character.level = action.payload; },
    setCharacterCulture: (state, action) => { state.character.culture = action.payload; },
    resetCharacterAbilities: (state, action) => {
      state.character.physical = { name: 'physical', value: state.character.physical.value, prof: false };
      state.character.education = { name: 'education', value: state.character.education.value, prof: false };
      state.character.technical = { name: 'technical', value: state.character.technical.value, prof: false };
      state.character.social = { name: 'social', value: state.character.social.value, prof: false };
      state.character.awareness = { name: 'awareness', value: state.character.awareness.value, prof: false };
    },
    setCharacterPhysical: (state, action) => { state.character.physical = action.payload; },
    setCharacterEducation: (state, action) => { state.character.education = action.payload; },
    setCharacterTechnical: (state, action) => { state.character.technical = action.payload; },
    setCharacterSocial: (state, action) => { state.character.social = action.payload; },
    setCharacterAwareness: (state, action) => { state.character.awareness = action.payload; },
    setCharacterSkills: (state, action) => { state.character.skills = action.payload; },
    setCharacterFeatures: (state, action) => { state.character.features = action.payload; },
    setCharacterGp: (state, action) => { state.character.gp = action.payload; },
    setCharacterSp: (state, action) => { state.character.sp = action.payload; },
    setCharacterCp: (state, action) => { state.character.cp = action.payload; },
    setCharacterTreasures: (state, action) => { state.character.treasures = action.payload; },
    setCharacterGear: (state, action) => { state.character.gear = action.payload; },
    setCharacterSpecialAmmo: (state, action) => { state.character.specializedAmmo = action.payload; },
    setCharacterWeapons: (state, action) => { state.character.weapons = action.payload; },
    setCharacterSkin: (state, action) => { state.character.skin = action.payload; },
    setCharacterHeight: (state, action) => { state.character.height = action.payload; },
    setCharacterWeight: (state, action) => { state.character.weight = action.payload; },
    setCharacterEyes: (state, action) => { state.character.eyes = action.payload; },
    setCharacterHair: (state, action) => { state.character.hair = action.payload; },
    setCharacterTraits: (state, action) => { state.character.traits = action.payload; },
    setCharacterPerks: (state, action) => { state.character.perks = action.payload; },
    setCharacterAnimalsOwned: (state, action) => { state.character.animalsOwned = action.payload; },
    setCharacterBlessings: (state, action) => { state.character.blessings = action.payload; },
    setCharacterDivineRelics: (state, action) => { state.character.divineRelics = action.payload; },
    setCharacterAllies: (state, action) => { state.character.alliesAndContacts = action.payload; },
    setCharacterBackstory: (state, action) => { state.character.backstory = action.payload; },
    setCharacterLanguages: (state, action) => { state.character.languages = action.payload; },
    setCompanionName: (state, action) => { state.character.companion.name = action.payload; },
    setCompanionCreatureType: (state, action) => { state.character.companion.creatureType = action.payload; },
    setCompanionSize: (state, action) => { state.character.companion.size = action.payload; },
    setCompanionExhaustion: (state, action) => { state.character.companion.exhaustion = action.payload; },
    setCompanionSpeed: (state, action) => { state.character.companion.speed = action.payload; },
    setCompanionResistances: (state, action) => { state.character.companion.resistances = action.payload; },
    setCompanionVulnerabilities: (state, action) => { state.character.companion.vulnerabilities = action.payload; },
    setCompanionAttackDice: (state, action) => { state.character.companion.attackDice.display = action.payload; },
    setCompanionDefenseBonus: (state, action) => { state.character.companion.defenseBonus = action.payload; },
    setCompanionAttacks: (state, action) => { state.character.companion.attacks = action.payload; },
    setCompanionSpecialAbilities: (state, action) => { state.character.companion.specialAbilities = action.payload; },
    setCompanionPhysical: (state, action) => { state.character.companion.physical.value = action.payload; },
    setCompanionEducation: (state, action) => { state.character.companion.education.value = action.payload; },
    setCompanionTechnical: (state, action) => { state.character.companion.technical.value = action.payload; },
    setCompanionSocial: (state, action) => { state.character.companion.social.value = action.payload; },
    setCompanionAwareness: (state, action) => { state.character.companion.awareness.value = action.payload; },
  }
});

export const {
  setCharacterData,
  setCharacterName,
  setCharacterExhaustion,
  setCharacterAge,
  setCharacterRestDice,
  setCharacterGender,
  setCharacterWeaponMod,
  setCharacterClass,
  setCharacterLevel,
  setCharacterCulture,
  resetCharacterAbilities,
  setCharacterPhysical,
  setCharacterEducation,
  setCharacterTechnical,
  setCharacterSocial,
  setCharacterAwareness,
  setCharacterSkills,
  setCharacterFeatures,
  setCharacterGp,
  setCharacterSp,
  setCharacterCp,
  setCharacterTreasures,
  setCharacterGear,
  setCharacterSpecialAmmo,
  setCharacterWeapons,
  setCharacterSkin,
  setCharacterHeight,
  setCharacterWeight,
  setCharacterEyes,
  setCharacterHair,
  setCharacterTraits,
  setCharacterPerks,
  setCharacterAnimalsOwned,
  setCharacterBlessings,
  setCharacterDivineRelics,
  setCharacterAllies,
  setCharacterBackstory,
  setCharacterLanguages,
  setCompanionName,
  setCompanionCreatureType,
  setCompanionSize,
  setCompanionExhaustion,
  setCompanionSpeed,
  setCompanionResistances,
  setCompanionVulnerabilities,
  setCompanionAttackDice,
  setCompanionDefenseBonus,
  setCompanionAttacks,
  setCompanionSpecialAbilities,
  setCompanionPhysical,
  setCompanionEducation,
  setCompanionTechnical,
  setCompanionSocial,
  setCompanionAwareness,
} = characterSlice.actions;

export const fetchCharacterState = (state: RootState) => state.character.character;

export default characterSlice.reducer;
