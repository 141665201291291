import api from '../config/axios';
import { getRandomInt } from '../tools/utils';


// GET Routes

export const getCharacters = async () => {
  try {
    const auth = { withCredentials: true };
    const res = await api.get('/api/character', auth) as any;
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const getCharacter = async (characterId: number) => {
  try {
    const auth = { withCredentials: true };
    const res = await api.get(`/api/character/${characterId}`, auth) as any;
    res.data.restDice = JSON.parse(res.data.restDice);
    res.data.attackDice = JSON.parse(res.data.attackDice);
    res.data.defenseDice = JSON.parse(res.data.defenseDice);
    res.data.physical = JSON.parse(res.data.physical);
    res.data.education = JSON.parse(res.data.education);
    res.data.technical = JSON.parse(res.data.technical);
    res.data.social = JSON.parse(res.data.social);
    res.data.awareness = JSON.parse(res.data.awareness);
    res.data.skills = JSON.parse(res.data.skills);
    res.data.animalsOwned = JSON.parse(res.data.animalsOwned);
    res.data.specialized_ammo = res.data.specialized_ammo.filter((ammo: any) => ammo.id !== null);
    res.data.weapons = res.data.weapons.filter((weapon: any) => weapon.id !== null);
    res.data.gear = res.data.gear.filter((gear: any) => gear !== null);
    res.data.features = res.data.features.filter((feature: any) => feature !== null);
    res.data.perks = res.data.perks.filter((perk: any) => perk !== null);
    res.data.blessings = res.data.blessings.filter((blessing: any) => blessing !== null);
    res.data.traits = JSON.parse(res.data.traits);
    res.data.divineRelics = JSON.parse(res.data.divineRelics);
    res.data.companion = JSON.parse(res.data.companion);
    return { ...res.data, specializedAmmo: res.data.specialized_ammo };
  } catch (error) {
    console.log(error);
  }
};

// POST Routes

export const addCharacter = async () => {
  try {
    const auth = { withCredentials: true };
    await api.post('/api/character', {
      name: "Unnamed Character",
      exhaustion: 0,
      speed: 30,
      age: 0,
      faithPoints: 0,
      restDice: JSON.stringify({ amount: 0, type: 0, display: '' }),
      gender: '',
      breathersTaken: 0,
      weaponExhaustionMod: 0,
      characterClass: '',
      attackDice: JSON.stringify({ amount: 0, type: 0, display: '' }),
      defenseDice: JSON.stringify({ amount: 0, type: 0, display: '' }),
      level: 0,
      culture: '',
      dailySlots: 1,
      physical: JSON.stringify({ name: 'physical', value: getRandomInt(1, 5), prof: false }),
      education: JSON.stringify({ name: 'education', value: getRandomInt(1, 5), prof: false }),
      technical: JSON.stringify({ name: 'technical', value: getRandomInt(1, 5), prof: false }),
      social: JSON.stringify({ name: 'social', value: getRandomInt(1, 5), prof: false }),
      awareness: JSON.stringify({ name: 'awareness', value: getRandomInt(1, 5), prof: false }),
      skills: JSON.stringify([
        { name: 'acrobatics', type: 'tech', bonus: 0 },
        { name: 'athletics', type: 'phy', bonus: 0 },
        { name: 'convince', type: 'soc', bonus: 0 },
        { name: 'craft', type: 'tech', bonus: 0 },
        { name: 'decipher', type: 'ed', bonus: 0 },
        { name: 'diplomacy', type: 'soc', bonus: 0 },
        { name: 'finesse', type: 'tech', bonus: 0 },
        { name: 'grapple', type: 'phy', bonus: 0 },
        { name: 'intimidation', type: 'phy', bonus: 0 },
        { name: 'investigation', type: 'awar', bonus: 0 },
        { name: 'intuition', type: 'soc', bonus: 0 },
        { name: 'knowledge', type: 'ed', bonus: 0 },
        { name: 'medicine', type: 'ed', bonus: 0 },
        { name: 'nature', type: 'ed', bonus: 0 },
        { name: 'perform', type: 'soc', bonus: 0 },
        { name: 'perception', type: 'awar', bonus: 0 },
        { name: 'reflex', type: 'tech', bonus: 0 },
        { name: 'subtle', type: 'awar', bonus: 0 },
        { name: 'tracking', type: 'awar', bonus: 0 }
      ]),
      gp: 0,
      sp: 0,
      cp: 0,
      treasures: '',
      animalsOwned: '[]',
      divineRelics: '[]',
      skin: '',
      height: '',
      weight: '',
      eyes: '',
      hair: '',
      traits: '[]',
      alliesAndContacts: '',
      backstory: '',
      languages: 'Broken Creation',
      companion: {"name":"","creatureType":"","size":"","exhaustion":"","speed":"","resistances":"","vulnerabilities":"","attackDice":{"amount":0,"type":0,"display":""},"defenseBonus":"","attacks":"","specialAbilities":"","physical":{"name":"","value":0,"prof":false},"education":{"name":"","value":0,"prof":false},"technical":{"name":"","value":0,"prof":false},"social":{"name":"","value":0,"prof":false},"awareness":{"name":"","value":0,"prof":false}}
    }, auth);
  } catch (error) {
    console.log(error);
  }
};

// DELETE Routes

export const deleteCharacter = async (id: number) => {
  try {
    const auth = { withCredentials: true };
    await api.delete(`/api/character/${id}`, auth);
  } catch (error) {
    console.log(error);
  }
};

// PUT Routes

export const updateEquipedWeaponAmmo = async (ammoId: number, weaponId: number) => {
  try {
    const auth = { withCredentials: true };
    await api.put(`/api/character/weapons/{"ammoId": ${ammoId}, "weaponId": ${weaponId}}`, auth);
  } catch (error) {
    console.log(error);
  }
};


export const updateCharacter = async (character: any, features: any, perks: any, gear: any, blessings: any, weapons: any, specializedAmmo: any) => {
  try {
    const auth = { withCredentials: true };
    const oldCharacterData = await getCharacter(character.id);
    const newFeatures = features.filter((feature: any) => {
      if (feature.index !== 'extra-attack') {
        return !oldCharacterData.features.some((oldFeature: any) => oldFeature.id === feature.id);
      }
    });
    const newPerks = perks.filter((perk: any) => {
      return !oldCharacterData.perks.some((oldPerk: any) => oldPerk.id === perk.id);
    });
    const newGear = gear.filter((_gear: any) => {
      return !oldCharacterData.gear.some((oldGear: any) => oldGear.id === _gear.id);
    });
    const newAmmo = specializedAmmo.filter((_specializedAmmo: any) => {
      return !oldCharacterData.specializedAmmo.some((oldAmmo: any) => oldAmmo.id === _specializedAmmo.id);
    });
    const newBlessings = blessings.filter((blessing: any) => {
      return !oldCharacterData.blessings.some((oldBlessing: any) => oldBlessing.id === blessing.id);
    });
    const newWeapons = weapons.filter((weapon: any) => {
      return !oldCharacterData.weapons.some((oldWeapon: any) => oldWeapon.id === weapon.id);
    });

    const updatedAttachedAmmo = weapons.filter((weapon: Weapon) => {
      const oldWeapon = oldCharacterData.weapons.find((_weapon: Weapon) => _weapon.id === weapon.id);
      if (oldWeapon && oldWeapon.specializedAmmo !== weapon.specializedAmmo) {
        return weapon;
      }
    });
    updatedAttachedAmmo.forEach(async (weapon: Weapon) => {
      await updateEquipedWeaponAmmo(weapon.specializedAmmo ? weapon.specializedAmmo.id : null, weapon.id);
    });
    
    
    // Edit character data
    await api.put(`/api/character/character/${character.id}`, character, auth);
    newFeatures.forEach(async (feature: any) => {
      await api.post('/api/character/features', { characterId: character.id, featureId: feature.id }, auth);
    });
    newPerks.forEach(async (perk: any) => {
      await api.post('/api/character/perks', { characterId: character.id, perkId: perk.id }, auth);
    });
    newGear.forEach(async (gear: any) => {
      await api.post('/api/character/gear', { characterId: character.id, gearId: gear.id }, auth);
    });
    newAmmo.forEach(async (ammo: any) => {
      await api.post('/api/character/ammo', { characterId: character.id, ammoId: ammo.id, qty: 1 }, auth);
    });
    newBlessings.forEach(async (blessing: any) => {
      await api.post('/api/character/blessings', { characterId: character.id, blessingId: blessing.id }, auth);
    });
    newWeapons.forEach(async (weapon: any) => {
      await api.post('/api/character/weapons', { characterId: character.id, weaponId: weapon.id }, auth);
    });
    
    // Delete character data
    const deletedGear = oldCharacterData.gear.filter((oldGear: any) => !gear.some((newGear: any) => newGear.id === oldGear.id));
    const deletedAmmo = oldCharacterData.specializedAmmo.filter((oldAmmo: any) => !specializedAmmo.some((newAmmo: any) => newAmmo.id === oldAmmo.id));
    const deletedWeapons = oldCharacterData.weapons.filter((oldWeapon: any) => !weapons.some((newWeapon: any) => newWeapon.id === oldWeapon.id));
    deletedGear.forEach(async (gear: any) => {
      await api.delete(`/api/character/gear/${gear.id}`, auth);
    });
    deletedAmmo.forEach(async (ammo: any) => {
      await api.delete(`/api/character/ammo/${ammo.id}`, auth);
    });
    deletedWeapons.forEach(async (weapon: any) => {
      await api.delete(`/api/character/weapons/${weapon.id}`, auth);
    });

    // Remove attached ammo if it's ammo was deleted on the specialized ammo table
    oldCharacterData.weapons.forEach(async (weapon: Weapon) => {
      if (weapon.specializedAmmo) {
        const ammo: SpecializedAmmo = deletedAmmo.find((ammo: SpecializedAmmo) => weapon.specializedAmmo.id === ammo.id);
        if (ammo && ammo.id === weapon.specializedAmmo.id) {
          await updateEquipedWeaponAmmo(null, weapon.id);
        }
      }
    });
  } catch (error) {
    console.log(error);
  }
};
