import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { fetchCharacterState, setCompanionAttackDice, setCompanionAttacks, setCompanionAwareness, setCompanionCreatureType, setCompanionDefenseBonus, setCompanionEducation, setCompanionExhaustion, setCompanionName, setCompanionPhysical, setCompanionResistances, setCompanionSize, setCompanionSocial, setCompanionSpecialAbilities, setCompanionSpeed, setCompanionTechnical, setCompanionVulnerabilities } from "../../../redux/reducers/characterSlice";


interface CompanionState {
  companion: Companion
}

export default function SheetCompanionBlock() {
  const dispatch = useAppDispatch();
  const { companion }: CompanionState = useAppSelector(fetchCharacterState);

  
  return (
    <div className="character-sheet__companion">
      <div className="character-sheet__companion-header">
        <p>Companion</p>
      </div>

      <input
        className="character-sheet__field character-sheet__companion--name"
        value={companion.name}
        placeholder="NAME"
        onChange={(e) => dispatch(setCompanionName(e.target.value))}
      />
      <input
        className="character-sheet__field character-sheet__companion--creature-type"
        value={companion.creatureType}
        placeholder="CREATURE TYPE"
        onChange={(e) => dispatch(setCompanionCreatureType(e.target.value))}
      />
      <input
        className="character-sheet__field character-sheet__companion--size"
        value={companion.size}
        placeholder="SIZE"
        onChange={(e) => dispatch(setCompanionSize(e.target.value))}
        type="number"
      />

      <input
        className="character-sheet__field character-sheet__companion--exhaustion"
        value={companion.exhaustion}
        placeholder="EXHAUSTION"
        onChange={(e) => dispatch(setCompanionExhaustion(e.target.value))}
        type="number"
      />
      <input
        className="character-sheet__field character-sheet__companion--speed"
        value={companion.speed}
        placeholder="SPEED"
        onChange={(e) => dispatch(setCompanionSpeed(e.target.value))}
        type="number"
      />
      <p className="character-sheet__field character-sheet__companion--fight-or-flight">
        FIGHT OR FLIGHT: <span>Attack or Run</span>
      </p>

      <textarea
        className="character-sheet__field character-sheet__companion--resistances"
        value={companion.resistances}
        placeholder="RESISTANCES"
        onChange={(e) => dispatch(setCompanionResistances(e.target.value))}
      />
      <textarea
        className="character-sheet__field character-sheet__companion--vulnerabilities"
        value={companion.vulnerabilities}
        placeholder="VULNERABILITIES"
        onChange={(e) => dispatch(setCompanionVulnerabilities(e.target.value))}
      />
      <input
        className="character-sheet__field character-sheet__companion--attack-dice"
        value={companion.attackDice.display}
        placeholder="ATTACK DICE"
        onChange={(e) => dispatch(setCompanionAttackDice(e.target.value))}
      />
      <input
        className="character-sheet__field character-sheet__companion--defense-bonus"
        value={companion.defenseBonus}
        placeholder="DEFENSE BONUS"
        onChange={(e) => dispatch(setCompanionDefenseBonus(e.target.value))}
        type="number"
      />

      <textarea
        className="character-sheet__field character-sheet__companion--attacks"
        value={companion.attacks}
        placeholder="ATTACKS"
        onChange={(e) => dispatch(setCompanionAttacks(e.target.value))}
      />
      <textarea
        className="character-sheet__field character-sheet__companion--special-abilities"
        value={companion.specialAbilities}
        placeholder="SPECIAL ABILITIES"
        onChange={(e) => dispatch(setCompanionSpecialAbilities(e.target.value))}
      />

      <label className="character-sheet__field character-sheet__companion--physical">
        PHY: 
        <input
          value={companion.physical.value}
          onChange={(e) => dispatch(setCompanionPhysical(e.target.value))}
          type="number"
        />
      </label>
      <label className="character-sheet__field character-sheet__companion--education">
        ED: 
        <input
          value={companion.education.value}
          onChange={(e) => dispatch(setCompanionEducation(e.target.value))}
          type="number"
        />
      </label>
      <label className="character-sheet__field character-sheet__companion--technical">
        TECH: 
        <input
          value={companion.technical.value}
          onChange={(e) => dispatch(setCompanionTechnical(e.target.value))}
          type="number"
        />
      </label>
      <label className="character-sheet__field character-sheet__companion--social">
        SOC: 
        <input
          value={companion.social.value}
          onChange={(e) => dispatch(setCompanionSocial(e.target.value))}
          type="number"
        />        
      </label>
      <label className="character-sheet__field character-sheet__companion--awareness">
        AWAR: 
        <input
          value={companion.awareness.value}
          onChange={(e) => dispatch(setCompanionAwareness(e.target.value))}
          type="number"
        />
      </label>
    </div>
  );
}
